import { IOldTranslatesForCompatibility, ITranslations } from '../ITranslations';

const translate: ITranslations | IOldTranslatesForCompatibility = {
  logoTitle: 'ООО «Антифишинг»',
  '404Page': {
    title: 'Данной страницы не существует',
    message: 'Запрошенный объект не найден',
    button: 'На главную',
  },
  '403Page': {
    title: 'Доступ закрыт',
    message: 'Чтобы открыть раздел, обратитесь к администратору. Если у вас остались вопросы, наша служба поддержки на связи:',
  },
  serviceUnavailable: {
    title: 'Сервис временно недоступен',
    message:
      'Мы уже знаем о проблеме и работаем над тем, чтобы все заработало как можно быстрее. Уточнить сроки и задать любые вопросы можно команде поддержки:',
  },
  errorPage: {
    writeUs: 'Напишите нам',
    orCall: 'Или позвоните',
  },
  featureUnavailablePage: {
    title: 'Данная функция недоступна',
    message:
      'Мы уже делаем все возможное, чтобы возобновить работу. Если у вас есть вопросы обратитесь в службу поддержки:',
  },
  phrases: {
    imageIsTooSmall: 'Изображение слишком маленькое. Минимальный размер изображения 600 x 500 px.',
    format: 'формат',
    competencyMap: "Карта компетенции",
    teamCompetencyMap: "Карта компетенций команды",
    trainingNotSetYet:"Обучение не назначено",
    rename: 'Переименовать',
    noTrainingForThisSystem: "Для этой системы обучение не предусмотрено, проверь обязательные юниты на вкладке знания",
    noMandatoryUnitsForTheRole:"У ролей отсутствуют обязательные юниты",
    assignUnitsMessage: "Рекомендуем назначить обязательные юниты в настройках проектных ролей",
    setupProjectRoles: "Настроить проектные роли",
    profileCharacteristicsErrorMessage1: "Характеристики анкеты не пересекаются с характеристиками обязательных юнитов",
    profileCharacteristicsErrorMessage2: "В анкете нет характеристик из обязательных юнитов для проектных ролей",
    fillOutTheProfile: "Заполнить анкету",
    createCopy: 'Создать копию',
    newFile: 'Новый файл',
    fileName: 'Название файла',
    folderName: 'Название папки',
    emptyFileValidationError: 'Значение не может быть пустым',
    newFolder: 'Новая папка',
    settings: 'Настройки',
    selectType: 'Выберите тип',
    comment: 'Пояснение',
    project: 'Проект',
    systems: 'Системы',
    mandatory: 'Обязательные',
    optional: 'Необязательные',
    publish: 'Опубликовать',
    published: 'Опубликован',
    unpublish: 'Снять с публикации',
    edit: 'Редактировать',
    delete: 'Удалить',
    junior: 'Новичок',
    middle: 'Мидл',
    champion: 'Чемпион',
    jiraFields: 'Jira поля',
    total: 'Всего',
    never: 'Никогда',
    yesterday: 'Вчера',
    ago: 'Назад',
    minuteAgo: 'Минуту назад',
    now: 'Сейчас',
    searchTable: 'Поиск по таблице',
    toLearning: 'К знаниям',
    theory: 'Теория',
    quiz: 'Квиз',
    practice: 'Практика',
    min: 'Мин',
    task: 'Задача',
    question: 'Вопрос',
    questions: 'Вопросы',
    check: 'Проверить',
    retakeIn: 'Пересдать через',
    choosePracticeLang: 'Выберите язык для прохождения практики',
    chooseTheLang: 'Выберите язык',
    langSelection: 'Выбор языка',
    fold: 'Свернуть',
    expand: 'Развернуть',
    of: 'Из',
    completeUnit: 'Завершить юнит',
    quit: 'Выход',
    results: 'Результаты',
    correctAnswer: 'Вы ответили правильно',
    wrongAnswer: 'Вы ответили неправильно',
    title: 'Заголовок',
    subtitle: 'Подзаголовок',
    users: 'Пользователи',
    learning: 'Знания',
    add: 'Добавить',
    cancel: 'Отменить',
    save: 'Сохранить',
    confirmDeleteRecord: 'Удалить запись?',
    confirmDeleteRecordLabel: 'Вы уверены, что хотите удалить запись?',
    yes: 'Да',
    addRole: 'Добавить Роль',
    units: 'Юниты',
    rights: 'Права',
    description: 'Описание',
    name: 'Имя',
    nameTitle: 'Название',
    user: 'Пользователь',
    fromWhatDate: 'С какой даты',
    toWhatDate: 'По какую дату',
    events: 'События',
    realizationStatus: 'Статус реализации',
    event: 'Событие',
    idEvents: 'ID события',
    timeEvents: 'Время события',
    text: 'Текст',
    dns: 'DNS',
    ip: 'IP',
    actions: 'Действия',
    admin: 'Администратор',
    projectRoles: 'Проектные роли',
    exclude: 'Исключить',
    include: 'Включить',
    saveDraft: 'В черновик',
    preview: 'Предпросмотр',
    systemRoles: 'Системные роли',
    systemRole: 'Системная роль',
    notifications: 'Уведомления',
    reading: 'Чтение',
    creating: 'Создание',
    updating: 'Обновление',
    deleting: 'Удаление',
    verification: 'Верификация',
    sync: 'Синхронизировать',
    lastSynchronization: 'Последняя синхронизация',
    integrations: 'Интеграции',
    activeDirectory: 'Active Directory',
    login: 'Логин',
    password: 'Пароль',
    confirmPassword: 'Подтверждение пароля',
    userGroup: 'Группа пользователей',
    projectUser: 'Пользователи проекта',
    editProject: 'Редактировать проект',
    addProject: 'Добавить проект',
    integratedWithTheSystem: 'Объединено с Системой',
    youDoNotHavePermissionsToCreateSystems: 'У вас нет прав для создания систем. Обратитесь к администратору',
    fieldShouldContainNameOfProject: 'Данное поле должно содержать полное название Проекта',
    fieldShouldContainUniqueIdentifierOfProject:
      'Данное поле должно содержать уникальный идентификатор проекта в организации. В случае отсутствия кода указывается “Отсутствует“',
    jiraLink: 'Ссылка в JIRA',
    jiraLinkDescription: 'Ссылка на страницу данного продукта в Jira',
    anyAdditionalProjectInformation: 'Любая дополнительная информация по проекту',
    nameOfUnit: 'Название юнита',
    specifications: 'Значение',
    recommendedForRoles: 'Рекомендовано для ролей',
    unitCover: 'Обложка юнита',
    imageDiagram: 'Схема изображения',
    system: 'Система',
    single: 'Один',
    one: 'Один',
    multi: 'Мульти',
    langNotChosen: 'Язык не выбран',
    doNotRandomize: 'Не рандомизировать',
    answers: 'Ответы',
    addAnswer: 'Добавить ответ',
    correct: 'Верный',
    minutes: 'Минуты',
    reward: 'Награда',
    languages: 'Языки',
    type: 'Тип',
    resetChanges: 'Сбросить все изменения',
  },
  navigation: {
    projects: 'Проекты',
    systems: 'Системы',
    settings: 'Настройки',
    analytics: 'Аналитика',
    logout: 'Выйти',
    learning: 'Знания',
  },
  systemRoles: {
    'plugin-settings-logger-is-allow': 'Доступ к журналированию',
    'global-allow-any': 'Доступ ко всему',
    'is-have-access-to-projects': 'Доступ к проектам',
    'project-in-team-read': 'Чтение проектов в команде',
    'project-not-in-team-access': 'Доступ к проектам без проектных прав',
    'project-not-in-team-permission-update-team-project': 'Доступ к редактированию команды проектов без проектных прав',
    'project-not-in-team-permission-update-fields-project': 'Доступ к редактированию полей проектов без проектных прав',
    'project-not-in-team-permission-update-project': 'Доступ к обновлению проектов без проектных прав',
    'project-not-in-team-permission-jira-intergration': 'Доступ к интеграции АС с Jira',
    'project-not-in-team-permission-create-project': 'Доступ к созданию проектов без проектных прав',
    'project-not-in-team-permission-delete-project': 'Доступ к удалению проектов без проектных прав',
    'project-not-in-team-permission-create-application': 'Доступ к созданию систем без проектных прав',
    'project-not-in-team-permission-update-application': 'Доступ к обновлению систем без проектных прав',
    'project-not-in-team-permission-delete-application': 'Доступ к удалению систем без проектных прав',
    'project-not-in-team-permission-report-access': 'Доступ к отчётам систем без проектных прав',
    'project-not-in-team-permission-custom-requirement-access':
      'Доступ к локальным требованиям систем без проектных прав',
    'project-not-in-team-permission-accept-cancel-access':
      'Доступ к принятию/исключению требований систем без проектных прав',
    'project-not-in-team-permission-show-regulators': 'Доступ к просмотру требований внешних регуляторов\n',
    'project-not-in-team-permission-new-comments-access': 'Доступ к получению новых комментарий без проектных прав',
    'project-not-in-team-permission-show-unverified-requirements-access': 'Просмотр неверифицированных требований',
    'settings-is-allow': 'Доступ к настройкам',
    'settings-specifications-delete': 'Доступ к удалению характеристик',
    'settings-specifications-create': 'Доступ к созданию характеристик',
    'settings-specifications-update': 'Доступ к обновлению характеристик',
    'settings-specifications-read': 'Доступ к чтению характеристик',
    'settings-requirements-delete': 'Доступ к удалению требований',
    'settings-requirements-create': 'Доступ к созданию требований',
    'settings-requirements-update': 'Доступ к обновлению требований',
    'settings-requirements-read': 'Доступ к чтению требований',
    'settings-requirement-vendors-delete': 'Доступ к удалению регуляторов',
    'settings-requirement-vendors-create': 'Доступ к созданию регуляторов',
    'settings-requirement-vendors-update': 'Доступ к обновлению регуляторов',
    'settings-requirement-vendors-read': 'Доступ к чтению регуляторов',
    'settings-tags-delete': 'Доступ к удалению тегов',
    'settings-tags-create': 'Доступ к созданию тегов',
    'settings-tags-update': 'Доступ к обновлению тегов',
    'settings-tags-read': 'Доступ к чтению тегов',
    'settings-statuses-delete': 'Доступ к удалению статусов',
    'settings-statuses-create': 'Доступ к созданию статусов',
    'settings-statuses-update': 'Доступ к обновлению статусов',
    'settings-statuses-read': 'Доступ к чтению статусов',
    'settings-requirements-accept': 'Доступ к верефикации требований',
    'settings-performers-delete': 'Доступ к удалению исполнителей требований',
    'settings-performers-create': 'Доступ к созданию исполнителей требований',
    'settings-performers-update': 'Доступ к обновлению исполнителей требований',
    'settings-performers-read': 'Доступ к чтению исполнителей требований',
    'settings-additional-columns-delete': 'Доступ к удалению дополнительных колонок',
    'settings-additional-columns-create': 'Доступ к созданию дополнительных колонок',
    'settings-additional-columns-update': 'Доступ к обновлению дополнительных колонок',
    'settings-additional-columns-read': 'Доступ к чтению дополнительных колонок',
    'settings-integrations-delete': 'Доступ к удалению интеграциям',
    'settings-integrations-create': 'Доступ к созданию интеграциям',
    'settings-integrations-update': 'Доступ к обновлению интеграциям',
    'settings-integrations-read': 'Доступ к чтению интеграциям',
    'settings-application-template-delete': 'Доступ к удалению шаблонов систем',
    'settings-application-template-create': 'Доступ к созданию шаблонов систем',
    'settings-application-template-update': 'Доступ к обновлению шаблонов систем',
    'settings-application-template-read': 'Доступ к чтению шаблонов систем',
    'settings-user-entity-delete': 'Доступ к удалению пользователей',
    'settings-user-entity-create': 'Доступ к созданию пользователей',
    'settings-user-entity-update': 'Доступ к обновлению пользователей',
    'settings-user-entity-read': 'Доступ к чтению пользователей',
    'settings-user-project-role-delete': 'Доступ к удалению проектных ролей',
    'settings-user-project-role-create': 'Доступ к созданию проектных ролей',
    'settings-user-project-role-update': 'Доступ к обновлению проектных ролей',
    'settings-user-project-role-read': 'Доступ к чтению проектных ролей',
    'settings-user-system-role-delete': 'Доступ к удалению системных ролей',
    'settings-user-system-role-create': 'Доступ к созданию системных ролей',
    'settings-user-system-role-update': 'Доступ к обновлению системных ролей',
    'settings-user-system-role-read': 'Доступ к чтению системных ролей',
    'settings-user-system-role-application-transfer': 'Есть возможность переносить/копировать системы',
    addRoleModal: {
      noRestrictions: 'Нет ограничений',
      accessToTheProjectsSection: 'Доступ к разделу "Проекты"',
      accessInProjectsWithoutAnyProjectRole: 'Доступ в проектах без проектной роли',
      rightsInProjectsWithoutAnyProjectRole: 'Права в проектах без проектной Роли',
      itIsPossibleToTransferOrCopySystems: 'Есть возможность переносить/копировать системы',
      notificationSettings: 'Настройка уведомлений',
      accessToSettingsIsAvailable: 'Есть доступ к настройкам',
      settingsFeaturesOfAS: 'Настройки - Характеристики АС',
      settingsTemplatesOfRequirements: 'Настройки - Шаблоны требований',
      settingsRequirementsOfExternalRegulatoryAuthorities: 'Настройки - Требования внешних регуляторов',
      settingsTagsForRequirements: 'Настройки - Тэги для требований',
      settingsStatuses: 'Настройки - Статусы',
      settingsExecutorsOfRequirements: 'Настройки - Исполнители требований',
      settingsAdditionalColumns: 'Настройки - Дополнительные колонки',
      settingsIntegrations: 'Настройки - Интеграции',
      settingsUsers: 'Настройки - Пользователи',
      settingsSystemRoles: 'Настройки - Системные роли',
      settingsProjectRoles: 'Настройки - Проектные роли',
      settingsTemplatesOfProfiles: 'Настройки - Шаблоны анкет',
      logging: 'Журналирование',
      riskProfile: 'Анкета рисков',
      creatingProjects: 'Создавать проекты',
      editingProjectTeams: 'Редактирование команды проекта',
      editingProjectFields: 'Редактирование полей проекта',
      deletingProjects: 'Удалять проекты',
      creatingSystems: 'Создавать системы',
      updatingSystems: 'Обновлять системы',
      deletingSystems: 'Удалять системы',
      accessToReports: 'Доступ к отчётам',
      viewingUnverifiedRequirements: 'Просмотр неверифицированных требований',
      accessToCreatingOrEditingLocalRequirements: 'Доступ к созданию/редактированию локальных требований',
      acceptingOrCancelingRequirements: 'Возможность принимать/отменять требования',
      viewingRequirementsOfExternalRegulatoryAuthorities: 'Просмотр требовании внешних регуляторов',
      integratingAsWithJira: 'Возможность интегрировать АС с Jira',
      settingTheRequirementsVerificationFlagWhenCreatingASystem:
        'Установка флага верификации требований при создании системы',
      terminatingAcceptanceTests: 'Возможность завершить ПСИ',
      notificationAboutChangingAllStatusesForRequirementsToOneOfTheAvailableValues:
        'Уведомление о переводе всех существующих “Статусов” для требований в одно из существующих значений',
      creatingANewProjectOrChangingTheCurrentProject: 'Создание нового / Изменение текущего проекта',
      creatingANewAsProfileInTheProject: 'Создание новой Анкеты АС в Проекте',
      changingAnExistingAsProfileInTheProject: 'Изменение существующей Анкеты АС в Проекте',
      transferringAnAsProfileToAnotherProject: 'Перенос Анкеты АС в другой Проект',
      copyingAnAsProfileToAnotherProject: 'Копирование Анкеты АС в другой Проект',
      updatingTheAsRequirementsSetAfterChangingTheRequirementsTemplates:
        'Обновление набора требований АС после изменения шаблонов требований',
      creatingANewRequirementsTemplate: 'Создание нового Шаблона требований',
      updatingARequirementsTemplate: 'Обновление Шаблона требования',
      deletingARequirementsTemplate: 'Удаление Шаблона требования',
    },
  },
  notificationPermissions: {
    CHANGE_STATUS: 'Уведомление о переводе всех существующих “Статусов” для требований в одно из существующих значений',
    UPSERT_PROJECT: 'Создание нового изменение текущего проекта',
    CREATE_APPLICATION: 'Cоздание новой Анкеты АС в Проекте',
    EDIT_APPLICATION: 'Изменение существующей Анкеты АС в Проекте',
    CREATE_COMMENT_JIRA: 'Создание нового комментария к требованию в Jira',
    CREATE_REQUIREMENT: 'Создание нового требования в АС',
    INCLUDE_EXCLUDE_REQUIREMENT: 'Принятие/исключение требований в уже заполненных анкетах (красные/ синие маркеры)',
    CREATE_TEMPLATE: 'Создание нового Шаблона требований',
    EDIT_REQUIREMENT: 'Уведомление об изменении требования',
    CREATE_ADMIN: 'Уведомление добавлении нового администратора (в т.ч. локального администратора)',
    EDIT_ROLES_GROUP: 'Уведомление об изменении состава групп (изменение в ролевой модели, добавление пользователей)',
    EDIT_PASSWORD: 'Уведомление об изменении пароля локальных УЗ',
    UPSERT_ADDITIONAL_COLUMN: 'Уведомление об изменении/ добавлении доп.колонок.',
    CREATE_SYSTEM_ROLE: 'Уведомление о создании новой системной или проектной роли',
    EDIT_ADDITIONAL_COLUMN_CONTENT: 'Изменение содержимого доп колонки',
    TRANSFER_APPLICATION: 'Перенос Анкеты АС в другой Проект',
    COPY_APPLICATION: 'Копирование Анкеты АС в другой Проект',
    EDIT_REQUIREMENT_TEMPLATE: 'Обновление Шаблона требования',
    DELETE_REQUIREMENT_TEMPLATE: 'Удаление Шаблона требованияе',
    REQUIREMENT_ACCEPT: 'Обновление набора требований АС после изменения шаблонов требований',
  },
  notificationEvents: {
    PROJECT: 'Проект',
    APPLICATION: 'Анкета АС',
    JIRA: 'Jira',
    REQUIREMENT: 'Требования',
    REQUIREMENT_TEMPLATE: 'Шаблон требований',
    TRANSFER_APPLICATION: 'Анкета АС',
    REQUIREMENT_ACCEPT: 'Обновление частного набора требований к АС',
  },
  learningUserPage: {
    learningTitle: 'Знания',
    skillsMap: 'Карта компетенций',
    usersRating: 'Рейтинг участников',
    findByContext: 'Поиск контекста, юнита',
    showUnits: 'Показывать юниты',
  },
  learningAdminPage: {
    confirmDeleteUnit: 'Вы уверены, что хотите удалить юнит ?',
    deleteUnit: 'Удалить юнит',
    createUnitLabel: 'Создавайте здесь юниты с теорией, квизом и практикой',
    createUnit: 'Создать юнит',
    withoutContext: 'Без Контекста',
    download: 'Выгрузить',
    trainingReportDOCXLabel: 'Отчет по обучению (docx)',
    trainingReportPDFLabel: 'Отчет по обучению (pdf)',
    trainingReportDOCXFileLabel: 'Отчет по обучению.docx',
    trainingReportPDFFileLabel: 'Отчет по обучению.pdf',
    newUnit: 'НОВЫЙ ЮНИТ',
    noUnits: 'Нет юнитов',
  },
  userListSearch: {
    all: 'Все',
    published: 'Опубликованные',
    notPublished: 'Неопубликованные',
  },
  passingListPage: {
    systems: 'Системы',
    skillsMap: 'Карта компетенций',
    unitSearch: 'Поиск контекста, юнита',
    showUnits: 'Показывать юниты',
    unitsFilter: {
      all: 'Все',
      important: 'Обязательные',
      unimportant: 'Необязательные',
    },
    noUnits: 'Нет юнитов',
  },
  userRating: {
    usersProjectRole: 'Участник, проект или роль',
    championsFirst: 'Сначала чемпионы',
    newFirst: 'Сначала новички',
    usersRating: 'Рейтинг участников',
    fullName: 'ФИО',
    projects: 'Проекты',
    role: 'Роль',
    score: 'Баллы',
    level: 'Уровень',
  },
  userCardInfo: {
    pointsToNextLevel: 'Баллов до следующего уровня',
    pointsToCompletion: 'Баллов до полного прохождения',
    noAssignedUnits: 'У вас нет назначенных юнитов',
    allUnitsPassed: 'Вы прошли все назначенные юниты',
  },
  systemList: {
    noSystems: 'Нет доступных систем',
    finished: 'Прошел',
  },
  projectPage: {
    project: 'Проект',
    myProjects: 'Мои проекты',
    addProject: 'Добавить проект',
    system: 'Система',
    projectIsEmpty: 'Проект пуст',
    noResults: 'По вашим критериям поиска ничего не найдено',
    noProjects: 'Нет проектов',
    youAreNotAparticipantInTheProjects: 'Вы не являетесь участником проектов',
    noTeamForProject: 'У проекта нет команды',
    loadMore: 'Загрузить еще',
    allProjectsLoaded: 'Все проекты загружены',
    team: 'Команда',
    fullName: 'ФИО',
    projetRole: 'Роль',
    empty: '',
    addSystem: 'Добавить систему',
    editProject: 'Редактировать проект',
    noEstablishedSystems: 'В ваших проектах еще нет созданных АС',
    requirements: 'Требований',
    moveSystem: 'Перенести систему',
    copySystem: 'Копировать систему',
    training: 'Обучение',
    trainingPreview: 'Предпросмотр обучения',
    profile: 'Анкета',
    requirementsPreview: 'Предпросмотр требований',
    changeHistory: 'История изменений',
    downloadAsProfile: 'Выгрузить анкету АС',
    typeOfExecutors: 'Тип исполнителей',
    noCharacteristics: 'Нет характеристик',
    totalProjects: 'проектов',
    totalSystems: 'систем',
    requirementsCollection: 'Коллекции требований',
    deleteProject: 'Удалить проект',
    deleteProjectMessage: 'Вы уверены, что хотите удалить проект',
    userProjectTable: {
      systems: 'Систем',
      requirements: 'Требований',
      lastActivity: 'Последняя активность',
      actions: 'Действия',
      project: 'Проект',
      risks: 'Риски',
    },
  },
  passing: {
    correct: 'Верный ответ',
    option: 'Вариант',
    correctAnswersQuizCount: 'Правильных ответов на квиз',
    correctAnswersPracticeCount: 'Выполненных заданий',
    correctAnswersTotalCount: 'Общий счет',
    successResultsTitle: 'Поздравляем',
    successResultsSubTitle: 'Вы успешно выполнили задание',
    successResultsMessage: 'Чемпион по безопасности - это про вас',
    failResultsTitle: 'К сожалению вы не справились с заданием',
    failResultsMessage: 'Мы рекомендуем вам внимательно изучить теорию и повторить попытку',
    resultSuccessTitle: 'Молодец, вы достигли высоких результатов!',
    resultSuccessSubtitle: 'Отличная работа, продолжайте в том же духе!',
    resultOkTitle: 'Хороший результат! Вы ответили на большинство вопросов правильно.',
    resultOkSubtitle:
      'Перед следующим прохождением обратите внимание на вопросы, в которых допустили ошибки, и изучите юнит еще раз.',
    resultFailureTitle: 'Попробуйте еще раз, у вас недостаточно верных ответов, чтобы сдать юнит.',
    resultFailureSubtitle:
      'Обратите внимание на вопросы, проанализируйте их и изучите проблемные области. Пройдите квиз/практику еще раз, чтобы проверить, что понимаете все правильно.',
    success: 'Успешно',
    successMessage: 'Вы выполнил все задания правильно или допустил одну ошибку',
    neutral: 'Нейтрально',
    neutralMessage: 'Допущено более одной ошибки и более 50% ответов отвечено верно',
    failure: 'Провал',
    failureMessage: 'Допущено более 50% неверных ответоввал',
    quizResultMessage: '{{title}} Вы ответили на {{correctAnswers}} из {{totalQuestions}} вопросов и заработали',
  },
  analytics: {
    description: 'Показатели процесса управления требованиями и знаниями по ИБ',
    title: 'Аналитика',
    noResultsMessage: 'Скоро тут появятся график с данными',
    noResults: 'Пока ничего нет',
    resultsByProject: 'Результативность обучения по проектам',
    exportReports: 'Экспорт отчетов',
    employees: 'Человек',
    notStarted: 'Не начали',
    inProgress: 'В процессе',
    completed: 'Завершили',
  },
  login: {
    label: 'Войти в систему',
    btnLabel: 'Войти',
    login: 'Логин',
    password: 'Пароль',
    method: 'Метод входа',
    basic: 'Локальный вход',
    incorrectValid: 'Неверный логин или пароль',
  },
  settingsUser: {
    pageTitle: 'Управление пользователями',
    login: 'Логин',
    email: 'Почта',
    fio: 'ФИО',
    role: 'Роль',
    creationType: 'Тип создания',
    active: 'Активен',
    action: 'Действия',
    systemRole: 'Системная роль',
    status: 'Статус',
    creationTypeLocal: 'Локальный',
  },
  validation: {
    integer: 'Введите целое число',
    validationError: 'Ошибка валидации',
    unknownError: 'Неизвестная ошибка',
    notMoreThanSymbols: 'Не более {{number}} символов',
  },
  settings: {
    systemSpecificationsTitle: 'Характеристики АС',
    systemSpecificationsDes:
      'Содержит перечень разделов и значения, которые могут быть выбраны пользователем в каждом разделе при заполнении анкеты на АС',
    categories: 'Категории',
    values: 'Значения',
    requirementsExecutorTitle: 'Исполнители требований',
    requirementsExecutorDes:
      'Данная характеристика выбирается при создании анкеты для АС и используется для скрытия части полей или требований от внешних разработчиков.',
    executors: 'Исполнители',
    integrationsTitle: 'Интеграции',
    integrationsDes: 'Содержит перечень соединений с JIRA, Active Directory и Почтовой системой',
    mail: 'Почта',
    loggingTitle: 'Журналирование',
    loggingDes: 'Содержит журнал событий',
    eventLog: 'Журнал событий',
    templatesTitle: 'Шаблоны анкет',
    templatesDes: 'Содержит перечень шаблонов анкет',
    userManagementTitle: 'Управление пользователями',
    userManagementDes: 'Содержит перечень пользователей системы',
    users: 'Пользователи',
    projectRoles: 'Проектные роли',
    systemRoles: 'Системные роли',
  },
  projectRoles: {
    name: 'Название',
    recommendedUnits: 'Рекомендованные юниты для роли',
    editingProjectFields: 'Редактирование полей проекта',
    editingProjectTeams: 'Редактирование команды проекта',
    creatingNewProfiles: 'Создание новых анкет',
    editingProfiles: 'Редактирование анкет',
    generatingReports: 'Формирование отчетов',
    creatingRequirements: 'Создание требований',
    acceptingAndDeletingRequirements: 'Принятие и исключение требований',
    receivingNotificationsOfNewComments: 'Может получать уведомления о новых комментариях',
    viewingUnverifiedRequirements: 'Просмотр неверифицированных требований',
    viewingRequirementsOfExternalRegulatoryAuthorities: 'Просмотр требований внешних регуляторов',
    integratingASWithJira: 'Возможность интегрировать АС с Jira',
    managingRequirementsVerification: 'Просмотр неверифицированных требований',
    terminatingAcceptanceTests: 'Доступ к принятию/исключению требований систем без проектных прав',
    rolesUpdatedTitle: 'Роли были обновлены',
    rolesUpdatedMessage: 'Роли были обновлены. Юниты появятся позже.',
  },
  createUnit: {
    pageTitle: 'Создание Юнита',
    stepSettings: 'Настройки',
    stepTheory: 'Теория',
    stepTest: 'Квиз',
    stepPractice: 'Практика',
    stepResult: 'Результат',
    newUnit: 'Новый Юнит',
    taskOne: 'Задача 1',
    task: 'Задача',
    chapterNumber: 'Глава {{number}}',
    clearChapter: 'Очистить главу',
    addingInfo: 'Двойной щелчок для добавления компонента',
    chapters: 'Главы',
    text: 'Текст',
    heading1: 'Заголовок 1',
    heading2: 'Заголовок 2',
    heading3: 'Заголовок 3',
    p: 'Абзац',
    list: 'Список',
    listNumeric: 'Нумерованный',
    listBullet: 'Маркерованный',
    table: 'Таблица',
    columns: 'Колонки',
    rows: 'Строки',
    tableTitle: 'Заголовок таблицы',
    cellText: 'Текст ячейки',
    media: 'Медиа',
    img: 'Изображение',
    video: 'Видео',
    codeExample: 'Пример кода',
    codeLang: 'Язык',
    spacing: 'Интервал',
    spacingSize: 'Интервал {{size}}',
    nesting: 'Вложенность',
    hiddenBlock: 'Скрытый блок',
    tabs: 'Вкладки',
    tabCount: 'Вкладка {{count}}',
    tags: 'Теги',
    tagCount: 'Тег {{count}}',
    specialBlock: 'Специальный блок',
    chapterName: 'Название главы',
    title: 'Заголовок',
    link: 'Ссылка',
    linkHelper: "Указать полный путь к файлу c раширением. Пример 'http://domain/video.mp4'.",
    linkExtsError: 'Ссылка должна содержать полный путь к файлу, включая расширения .mp4/webm/ogg',
    file: 'Файл',
    color: 'Цвет',
    colorNameWarning: 'Предупреждение',
    colorNameSecondary: 'Определение',
    colorNameMain: 'Таблица',
    colorNameSuccess: 'Поощрение',
    colorNameError: 'Ошибка',
    colorNameInfo: 'Информация',
    quantity: 'Количество',
  },
  fileUpload: {
    drag: 'Перетащите или ',
    chooseFile: 'выберите файл',
    uploadRules: '{{list}}, не более {{maxSize}} MB',
    limitError: 'Файл превышает допустимый лимит',
    extensionError: 'Недопустимый формат файла',
    fileInfo: '{{ext}}, размер {{size}} MB',
  },
  ad: {
    table: {
      active: 'Активен',
    },
    deleteRowModal: {
      title: 'Удалить профиль',
      message: 'Пользователей, участвующих в проектах, в данном LDAP-профиле нет.',
      confirmationMessage: 'Вы уверены что хотите удалить {{name}} профиль?',
    },
    unableToDeleteRowModal: {
      title: 'Невозможно удалить профиль',
      message1:
        'В данном LDAP-профиле содержатся пользователи, участвующие в проектах. Для удаления профиля {{name}} необходимо отвязать пользователей от проектов.',
      message2: 'Пользователи, участвующие в проектах, в данном LDAP-профиле:',
      inProjects: 'в проектах',
    },
    addModal: {
      title: 'Добавление Active Directory',
      useAuthenticationWithThisASByDefault: 'Использовать аутентификацию с использованием данного AD по умолчанию:',
      activateThisConnection: 'Активировать данное подключение:',
      addLink: 'Добавить связь',
      fillBothFields: 'Необходимо заполнить оба поля',
    },
  },
  createProject: {
    nameTitle: 'Наименование продукта/проекта',
    nameSubtitle: 'Данное поле должно содержать полное наименование Проекта/Продукта.',
    idTitle: 'Идентификатор продукта/проекта',
    idSubtitle:
      'Данное поле должно содержать уникальный идентификатор проекта/продукта в организации. В случае отсутствия кода указывается “Отсутствует“.',
    jiraLinkTitle: 'Ссылка на проект в Jira',
    jiraLinkSubtitle: 'Ссылка на страницу проекта/продукта в Jira.',
    summaryTitle: 'Комментарий',
    summarySubtitle: 'Любая дополнительная информация по проекту.'
  },
  systemSpecificationsPage: {
    name: 'Название',
    "description": 'Описание',
    ordering: "Порядок отображения",
    "active": "Активен",
    "notActive": "Активен",
    showIfHaveValues: 'Показывать если есть значения',
    showIfHaveNotValues: "Показывать если нет значений",
    status: "Статус",
    category: 'Категория',
    categoryName: "Категория - {{name}}",
    categoryUntitled: 'Без названия',
    categoryEntity:  "Сущности категории {{name}}",
  },
  managerFilter: {
    searching: 'Поиск менеджеров...',
    noResults: 'Менеджеры не найдены',
    manager: 'Менеджер ИБ',
  },
  uploadImagePreview: {
    imageIsTooBig: 'Изображение слишком большое. Пожалуйста, загрузите картинку не более 5 MB.',
    incorrectImageFormat: 'Неверный формат файла. Допустимые форматы: PNG, JPG, JPEG.',
    dragAndDropMessage_1: 'Перетащите или',
    dragAndDropMessage_2: 'выберите файл',
    aspectRatio: 'соотношение сторон 4 x 3',
    minSize: 'минимальный размер 600 x 500 px',
    acceptableFormats: 'PNG, JPG, JPEG, не более 5 MB',
    backgroundRequirements: 'прозрачный или одноцветный фон',
  },
  passingResults: {
    completionTimeAndReward: 'Время прохождения и награда',
    completionTime: 'Время прохождения',
    completionTimeDescription:
      'Укажите примерное количество времени, которое сотрудник потратит на прохождение практических заданий. Это помогает спланировать обучение.',
    rewardDescription:
      'Общее количество баллов за юнит — 100. За каждое пройденное задание сотрудник может получить 25 баллов. Итоговый результат зависит от количества заданий, на которые он или она ответили правильно.',
  },
  codeEditor: {
    turnIntoQuestion: 'Превратить в вопрос',
    removeQuestion: 'Убрать вопрос',
    rename: "Переименовать",
  },
  finalResults: {
    successTitle: 'Успешно (от 70 до 100%)',
    successSubtitle: 'Все ответы верные',
    neutralTitle: 'Нейтрально (от 50 до 69%)',
    neutralSubtitle: 'Более 50% ответов верные, но есть хотя бы одна ошибка',
    failedTitle: 'Провал (от 0 до 49%)',
    failedSubtitle: 'Более 50% ответов неверные',
  },
  buttonsText: {
    newCategory: 'Новая категория',
    newValue: 'Новое значение',
    addButton: 'ДОБАВИТЬ',
    saveButton: 'СОХРАНИТЬ',
  },
};

export default translate;
