const translations = {
  logoTitle: 'Human Factor, LLC ',
  '404Page': {
    title: 'Page not found',
    message: '',
    button: 'To projects',
  },
  '403Page': {
    title: 'Access denied',
    message: 'Contact the administrator to open the section. If you have any questions, please contact our support',
  },
  serviceUnavailable: {
    title: 'Service Temporarily Unavailable',
    message:
      'We are aware of the problem and are working to get everything back up and running as soon as possible. If you have any questions or want to clarify ETAs, please feel free to contact our support team:',
  },
  errorPage: {
    writeUs: 'Write us at',
    orCall: 'or call',
  },
  featureUnavailablePage: {
    title: 'Feature unavailable',
    message:
      'We are doing our best to get the service back up and running. If you have any questions, please contact our support team:',
  },
  phrases: {
    imageIsTooSmall: 'The image is too small. Minimum image size is 600 x 500 px.',
    format: 'format',
    competencyMap: 'Expertise map',
    teamCompetencyMap: 'Team Expertise map',
    trainingNotSetYet: 'Training not set yet',
    noTrainingForThisSystem: 'There is no training for this system, check the required units in the knowledge tab',
    noMandatoryUnitsForTheRole: 'No mandatory units for the role',
    assignUnitsMessage: 'We recommend assigning mandatory units in the project roles settings.',
    setupProjectRoles: 'Setup project roles',
    profileCharacteristicsErrorMessage1:
      'The profile characteristics do not overlap with the characteristics of the required units',
    profileCharacteristicsErrorMessage2:
      'The profile does not contain characteristics from the mandatory units for project roles',
    fillOutTheProfile: 'Fill out the profile',
    rename: 'Rename',
    createCopy: 'Create copy',
    newFile: 'New file',
    fileName: 'File name',
    folderName: 'Folder name',
    emptyFileValidationError: 'No empty value allowed',
    newFolder: 'New folder',
    settings: 'Settings',
    project: 'Project',
    systems: 'Systems',
    mandatory: 'Mandatory',
    optional: 'Optional',
    publish: 'Publish',
    published: 'Published',
    unpublish: 'Unpublish',
    edit: 'Edit',
    delete: 'Delete',
    junior: 'Junior',
    middle: 'Middle',
    champion: 'Champion',
    jiraFields: 'Jira fields',
    total: 'Total',
    never: 'Never',
    yesterday: 'Yesterday',
    ago: 'Ago',
    minuteAgo: 'Minute ago',
    now: 'Now',
    searchTable: 'Search table',
    toLearning: 'To learning',
    theory: 'Theory',
    quiz: 'Quiz',
    practice: 'Practice',
    min: 'Min',
    task: 'Task',
    question: 'Question',
    questions: 'Questions',
    selectType: 'Select type',
    comment: 'Comment',
    check: 'Check',
    retakeIn: 'Retake in',
    choosePracticeLang: 'Select a language for the practical tasks',
    chooseTheLang: 'Choose the language',
    langSelection: 'Language selection',
    fold: 'Fold',
    expand: 'Expand',
    of: 'Of',
    completeUnit: 'Complete unit',
    quit: 'Quit',
    results: 'Results',
    correctAnswer: 'Correct answer',
    wrongAnswer: 'Wrong answer',
    title: 'Title',
    subtitle: 'Subtitle',
    learning: 'Learning',
    users: 'Users',
    add: 'Add',
    cancel: 'Cancel',
    save: 'Save',
    confirmDeleteRecord: 'Delete the entry?',
    confirmDeleteRecordLabel: 'Are you sure you want to delete the entry?',
    yes: 'Yes',
    addRole: 'Add Role',
    units: 'Units',
    rights: 'Rights',
    description: 'Description',
    name: 'Name',
    nameTitle: 'Name',
    user: 'User',
    fromWhatDate: 'From what date',
    toWhatDate: 'To what date',
    events: 'Events',
    realizationStatus: 'Realization status',
    event: 'Event',
    idEvents: 'ID events',
    timeEvents: 'Time events',
    text: 'Text',
    dns: 'DNS',
    ip: 'IP',
    actions: 'Actions',
    admin: 'Admin',
    projectRoles: 'Project roles',
    exclude: 'Exclude',
    include: 'Include',
    saveDraft: 'Save draft',
    preview: 'Preview',
    systemRoles: 'System roles',
    systemRole: 'System roles',
    notifications: 'Notifications',
    reading: 'Reading',
    creating: 'Creating',
    updating: 'Updating',
    deleting: 'Deleting',
    verification: 'Verification',
    sync: 'Synchronize',
    lastSynchronization: 'Last synchronization',
    integrations: 'Integrations',
    activeDirectory: 'Active Directory',
    login: 'Login',
    password: 'Password',
    confirmPassword: 'Password confirmation',
    userGroup: 'User group',
    projectUser: 'Project user',
    editProject: 'Edit project',
    addProject: 'Add project',
    integratedWithTheSystem: 'Integrated with the System',
    youDoNotHavePermissionsToCreateSystems: 'You do not have permissions to create systems. Contact your administrator',
    fieldShouldContainNameOfProject: 'This field should contain the full name of the Project',
    fieldShouldContainUniqueIdentifierOfProject:
      'This field should contain a unique identifier of the project in the organization. In case of absence of the code, “Absent” shall be indicated',
    jiraLink: 'Jira link',
    jiraLinkDescription: 'Link to this product page in Jira',
    anyAdditionalProjectInformation: 'Any additional information about the project',
    nameOfUnit: 'Name of unit',
    specifications: 'Specifications',
    recommendedForRoles: 'Recommended for roles',
    unitCover: 'Unit cover',
    imageDiagram: 'Image diagram',
    system: 'System',
    single: 'Single',
    one: 'One',
    multi: 'Multi',
    langNotChosen: 'No lang choosen',
    doNotRandomize: 'Do not randomize',
    answers: 'Answers',
    addAnswer: 'Add an answer',
    correct: 'Correct',
    minutes: 'Minutes',
    reward: 'Reward',
    languages: 'Languages',
    type: 'Type',
    resetChanges: 'Reset all changes',
  },
  navigation: {
    projects: 'Projects',
    systems: 'Systems',
    settings: 'Settings',
    logout: 'Logout',
    learning: 'Learning',
    analytics: 'Analytics',
  },
  systemRoles: {
    'plugin-settings-logger-is-allow': 'Access to logger',
    'global-allow-any': 'Access to anything',
    'is-have-access-to-projects': 'Access to projects',
    'project-in-team-read': 'Read projects access',
    'project-not-in-team-access': 'Access to projects without team',
    'project-not-in-team-permission-update-project': 'Access to update projects without team',
    'project-not-in-team-permission-update-team-project': 'Access to update project team without team',
    'project-not-in-team-permission-update-fields-project': 'Access to update project fields without team',
    'project-not-in-team-permission-create-project': 'Access to create projects without team',
    'project-not-in-team-permission-delete-project': 'Access to delete projects without team',
    'project-not-in-team-permission-create-application': 'Access to create systems without team',
    'project-not-in-team-permission-update-application': 'Access to update systems without team',
    'project-not-in-team-permission-delete-application': 'Access to delete systems without team',
    'project-not-in-team-permission-report-access': 'Access to reports in system without team',
    'project-not-in-team-permission-custom-requirement-access': 'Access to custom requirements without team',
    'project-not-in-team-permission-accept-cancel-access': 'Access to accept/cancel requirements without team',
    'project-not-in-team-permission-new-comments-access': 'Access to receive new comments without team',
    'settings-is-allow': 'Access to settings',
    'settings-specifications-delete': 'Access to delete specifications',
    'settings-specifications-create': 'Access to create specifications',
    'settings-specifications-update': 'Access to update specifications',
    'settings-specifications-read': 'Access to read specifications',
    'settings-requirements-delete': 'Access to delete requirements',
    'settings-requirements-create': 'Access to create requirements',
    'settings-requirements-update': 'Access to update requirements',
    'settings-requirements-read': 'Access to read requirements',
    'settings-requirement-vendors-delete': 'Access to delete vendor',
    'settings-requirement-vendors-create': 'Access to create vendor',
    'settings-requirement-vendors-update': 'Access to update vendor',
    'settings-requirement-vendors-read': 'Access to read vendor',
    'settings-tags-delete': 'Access to delete tags',
    'settings-tags-create': 'Access to create tags',
    'settings-tags-update': 'Access to update tags',
    'settings-tags-read': 'Access to read tags',
    'settings-statuses-delete': 'Access to delete statuses',
    'settings-statuses-create': 'Access to create statuses',
    'settings-statuses-update': 'Access to update statuses',
    'settings-statuses-read': 'Access to read statuses',
    'settings-performers-delete': 'Access to delete performer type',
    'settings-performers-create': 'Access to create performer type',
    'settings-performers-update': 'Access to update performer type',
    'settings-performers-read': 'Access to read performer type',
    'settings-additional-columns-delete': 'Access to delete additional columns',
    'settings-additional-columns-create': 'Access to create additional columns',
    'settings-additional-columns-update': 'Access to update additional columns',
    'settings-additional-columns-read': 'Access to read additional columns',
    'settings-integrations-delete': 'Access to delete integrations',
    'settings-integrations-create': 'Access to create integrations',
    'settings-integrations-update': 'Access to update integrations',
    'settings-integrations-read': 'Access to read integrations',
    'settings-application-template-delete': 'Access to delete system template',
    'settings-application-template-create': 'Access to create system template',
    'settings-application-template-update': 'Access to update system template',
    'settings-application-template-read': 'Access to read system template',
    'settings-user-entity-delete': 'Access to delete users',
    'settings-user-entity-create': 'Access to create users',
    'settings-user-entity-update': 'Access to update users',
    'settings-user-entity-read': 'Access to read users',
    'settings-user-project-role-delete': 'Access to delete project roles',
    'settings-user-project-role-create': 'Access to create project roles',
    'settings-user-project-role-update': 'Access to update project roles',
    'settings-user-project-role-read': 'Access to read project roles',
    'settings-user-system-role-delete': 'Access to delete system roles',
    'settings-user-system-role-create': 'Access to create system roles',
    'settings-user-system-role-update': 'Access to update system roles',
    'settings-user-system-role-read': 'Access to read system roles',
    'project-not-in-team-permission-jira-intergration': 'Access to AS integration with Jira',
    'project-not-in-team-permission-show-regulators': 'Access to viewing requirements of external regulators\n',
    'project-not-in-team-permission-show-unverified-requirements-access': 'Viewing unverified requirements',
    'settings-user-system-role-application-transfer': 'Ability to transfer/copy systems',
    addRoleModal: {
      noRestrictions: 'No restrictions',
      accessToTheProjectsSection: 'Access to the Projects section',
      accessInProjectsWithoutAnyProjectRole: 'Access in projects without any project role',
      rightsInProjectsWithoutAnyProjectRole: 'Rights in projects without any project role',
      itIsPossibleToTransferOrCopySystems: 'It is possible to transfer/copy systems',
      notificationSettings: 'Notification settings',
      accessToSettingsIsAvailable: 'Access to settings is available',
      settingsFeaturesOfAS: 'Settings - Features of AS',
      settingsTemplatesOfRequirements: 'Settings - Templates of requirements',
      settingsRequirementsOfExternalRegulatoryAuthorities: 'Settings - Requirements of external regulatory authorities',
      settingsTagsForRequirements: 'Settings - Tags for requirements',
      settingsStatuses: 'Settings - Statuses',
      settingsExecutorsOfRequirements: 'Settings - Executors of requirements',
      settingsAdditionalColumns: 'Settings - Additional columns',
      settingsIntegrations: 'Settings - Integrations',
      settingsUsers: 'Settings - Users',
      settingsSystemRoles: 'Settings - System roles',
      settingsProjectRoles: 'Settings - Project roles',
      settingsTemplatesOfProfiles: 'Settings - Templates of profiles',
      logging: 'Logging',
      riskProfile: 'Risk profile',
      creatingProjects: 'Creating projects',
      editingProjectTeams: 'Editing project teams',
      editingProjectFields: 'Editing project fields',
      deletingProjects: 'Deleting projects',
      creatingSystems: 'Creating systems',
      updatingSystems: 'Updating systems',
      deletingSystems: 'Deleting systems',
      accessToReports: 'Access to reports',
      viewingUnverifiedRequirements: 'Viewing unverified requirements',
      accessToCreatingOrEditingLocalRequirements: 'Access to creating/editing local requirements',
      acceptingOrCancelingRequirements: 'Accepting/canceling requirements',
      viewingRequirementsOfExternalRegulatoryAuthorities: 'Viewing requirements of external regulatory authorities',
      integratingAsWithJira: 'Integrating AS with Jira',
      settingTheRequirementsVerificationFlagWhenCreatingASystem:
        'Setting the requirements verification flag when creating a system',
      terminatingAcceptanceTests: 'Terminating acceptance tests',
      notificationAboutChangingAllStatusesForRequirementsToOneOfTheAvailableValues:
        'Notification about changing all Statuses for requirements to one of the available values',
      creatingANewProjectOrChangingTheCurrentProject: 'Creating a new project / Changing the current project',
      creatingANewAsProfileInTheProject: 'Creating a new AS Profile in the Project',
      changingAnExistingAsProfileInTheProject: 'Changing an existing AS Profile in the Project',
      transferringAnAsProfileToAnotherProject: 'Transferring an AS Profile to another Project',
      copyingAnAsProfileToAnotherProject: 'Copying an AS Profile to another Project',
      updatingTheAsRequirementsSetAfterChangingTheRequirementsTemplates:
        'Updating the AS requirements set after changing the requirements templates',
      creatingANewRequirementsTemplate: 'Creating a new Requirements Template',
      updatingARequirementsTemplate: 'Updating a Requirements Template',
      deletingARequirementsTemplate: 'Deleting a Requirements Template',
    },
  },
  notificationPermissions: {
    CHANGE_STATUS: 'Notification about changing all Statuses for requirements to one of the available values',
    UPSERT_PROJECT: 'Creating a new project / Changing the current project',
    CREATE_APPLICATION: 'Creating a new AS Profile in the Project',
    EDIT_APPLICATION: 'Changing an existing AS Profile in the Project',
    CREATE_COMMENT_JIRA: 'Creating a new comment in Jira requirement',
    CREATE_REQUIREMENT: 'Creating a new requirement in AS',
    INCLUDE_EXCLUDE_REQUIREMENT:
      'Accepting/canceling requirements in already filled out applications (red/blue markers)',
    CREATE_TEMPLATE: 'Creating a new Requirements Template',
    EDIT_REQUIREMENT: 'Notification about a requirement change',
    CREATE_ADMIN: 'Notification about adding a new administrator (including a local administrator)',
    EDIT_ROLES_GROUP: 'Notification about changing group composition (changes in the role model, adding users)',
    EDIT_PASSWORD: 'Notification about changing local user account passwords',
    UPSERT_ADDITIONAL_COLUMN: 'Notification about changing/adding additional columns',
    CREATE_SYSTEM_ROLE: 'Notification about creating a new system or project role',
    EDIT_ADDITIONAL_COLUMN_CONTENT: 'Changing the content of an additional column',
    TRANSFER_APPLICATION: 'Transferring an AS Profile to another Project',
    COPY_APPLICATION: 'Copying an AS Profile to another Project',
    EDIT_REQUIREMENT_TEMPLATE: 'Updating a Requirements Template',
    DELETE_REQUIREMENT_TEMPLATE: 'Deleting a Requirements Template',
    REQUIREMENT_ACCEPT: 'Updating the AS requirements set after changing the requirements templates',
  },
  learningUserPage: {
    learningTitle: 'Learning',
    skillsMap: 'Expertise map',
    usersRating: 'Leaderboard',
    findByContext: 'Name of context of unit',
    showUnits: 'Show units',
  },
  learningAdminPage: {
    confirmDeleteUnit: 'Are you sure you want to delete the unit?',
    deleteUnit: 'Delete unit',
    createUnitLabel: 'Create a units here with theory, quiz, and practice here',
    createUnit: 'Create a unit',
    withoutContext: 'Without Context',
    download: 'Download',
    trainingReportDOCXLabel: 'Training report (DOCX)',
    trainingReportPDFLabel: 'Training report (PDF)',
    trainingReportDOCXFileLabel: 'Training report.docx',
    trainingReportPDFFileLabel: 'Training report.pdf',
    newUnit: 'New unit',
    noUnits: 'No units',
  },
  userListSearch: {
    all: 'All',
    published: 'Published',
    notPublished: 'Not published',
  },
  passingListPage: {
    systems: 'Systems',
    skillsMap: 'Expertise map',
    unitSearch: 'Name of context or unit',
    showUnits: 'Show units',
    unitsFilter: {
      all: 'All',
      important: 'Mandatory',
      unimportant: 'Optional',
    },
    noUnits: 'No units available',
  },
  userRating: {
    usersProjectRole: 'Participant, project, or role',
    championsFirst: 'Champions first',
    newFirst: 'Juniors first',
    usersRating: 'Leaderboard',
    fullName: 'Full name',
    projects: 'Projects',
    role: 'Role',
    score: 'Score',
    level: 'Level',
  },
  userCardInfo: {
    pointsToNextLevel: 'Points to next level',
    pointsToCompletion: 'Points to completion',
    noAssignedUnits: "You don't have any assigned units",
    allUnitsPassed: 'All units passed',
  },
  systemList: {
    noSystems: 'No systems available',
    finished: 'Finished',
  },
  projectPage: {
    project: 'Project',
    myProjects: 'My projects',
    addProject: 'Add project',
    system: 'System',
    projectIsEmpty: 'Project is empty',
    noResults: 'No results',
    noProjects: 'No projects',
    youAreNotAparticipantInTheProjects: 'You are not a project participant',
    noTeamForProject: 'No team for project',
    loadMore: 'Load more',
    allProjectsLoaded: 'All projects uploaded',
    team: 'Team',
    fullName: 'Full name',
    projetRole: 'Role',
    empty: '',
    addSystem: 'Add system',
    editProject: 'Edit project',
    noEstablishedSystems: 'Your projects do not yet have established AS',
    requirements: 'Requirements',
    moveSystem: 'Move system',
    copySystem: 'Copy system',
    training: 'Training',
    trainingPreview: 'Training preview',
    profile: 'Profile',
    requirementsPreview: 'Requirements preview',
    changeHistory: 'Change history',
    downloadAsProfile: 'Download system profile',
    typeOfExecutors: 'Type of executors',
    noCharacteristics: 'No characteristics',
    totalProjects: 'projects',
    totalSystems: 'systems',
    requirementsCollection: 'Requirements collection',
    deleteProject: 'Delete project',
    deleteProjectMessage: 'Are you shure you want to delete project',
    userProjectTable: {
      systems: 'Systems',
      requirements: 'Requirements',
      lastActivity: 'Last activity',
      actions: 'Actions',
      project: 'Project',
      risks: 'Risks',
    },
  },
  passing: {
    option: 'Option',
    correctAnswersQuizCount: 'Correct answers to quiz',
    correctAnswersPracticeCount: 'Completed tasks',
    correctAnswersTotalCount: 'Total score',
    successResultsTitle: 'Congratulations',
    successResultsSubTitle: "You've passed the task",
    successResultsMessage: 'You are a sec champ',
    failResultsTitle: 'Unfortunately, you did not complete the task',
    failResultsMessage: 'We recommend that you study the theory carefully and try again',
    resultSuccessTitle: 'Well done, you have achieved great results!',
    resultSuccessSubtitle: 'Great job, keep up!',
    resultOkTitle: 'Good result! You answered most of the questions correctly.',
    resultOkSubtitle:
      'Before the next try, pay attention to the questions in which you made mistakes, and study the unit again.',
    resultFailureTitle: "Try again, you don't have enough correct answers to pass the unit.",
    resultFailureSubtitle:
      'Pay attention to the question, analyze them, and review problem areas. Take the quiz or practice portion again to make sure you understand everything correctly.',
    success: 'Success',
    successMessage: 'All the tasks completed',
    neutral: 'Neutral',
    neutralMessage: 'More than one mistake was made and more than 50% of the answers were answered correctly',
    failure: 'Failure',
    failureMessage: 'More than 50% of answers were incorrect',
    quizResultMessage: "{{title}} You've answered {{correctAnswers}} of {{totalQuestions}} questions and got",
  },
  analytics: {
    description: 'Information security requirements and learning management process metrics',
    title: 'Analytics',
    noResults: 'Nothing here yet',
    noResultsMessage: 'Chart with data will appear here soon',
    resultsByProject: 'Learning progress by projects',
    exportReports: 'Export reports',
    employees: 'Employees',
    notStarted: 'Not started',
    inProgress: 'In progress',
    completed: 'Completed',
  },
  login: {
    label: 'Log in',
    btnLabel: 'Log in',
    login: 'Login',
    password: 'Password',
    method: 'Login method',
    basic: 'Basic authentication',
    incorrectValid: 'Invalid login or password',
  },
  settingsUser: {
    pageTitle: 'User management',
    login: 'Login',
    email: 'Email',
    fio: 'Full name',
    role: 'Role',
    creationType: 'Creation type',
    active: 'Active',
    action: 'Action',
    systemRole: 'System role',
    status: 'Status',
    creationTypeLocal: 'Local',
  },
  validation: {
    integer: 'Enter an integer',
    validationError: 'Validation error',
    unknownError: 'Unknown error',
    notMoreThanSymbols: 'Max {{number}} characters',
  },
  settings: {
    systemSpecificationsTitle: 'System specifications',
    systemSpecificationsDes:
      'List of sections and values the user can select in each section when filling out an system profile.',
    categories: 'Categories',
    values: 'Values',
    requirementsExecutorTitle: 'Requirements Executor',
    requirementsExecutorDes:
      'The user selects this feature when creating an system profile to hide some fields or requirements from external developers.',
    executors: 'Executors',
    integrationsTitle: 'Integrations',
    integrationsDes: 'List of integrations with Active Directory, and the Mail  System.',
    mail: 'Mail',
    loggingTitle: 'Logging',
    loggingDes: 'Contains event log.',
    eventLog: 'Event log',
    templatesTitle: 'Templates of profiles',
    templatesDes: 'List of profile  templates.',
    userManagementTitle: 'User  management',
    userManagementDes: 'List of  system users.',
    users: 'Users',
    projectRoles: 'Project roles',
    systemRoles: 'System roles',
  },
  projectRoles: {
    name: 'Name',
    recommendedUnits: 'Recommended units for the role',
    editingProjectFields: 'Editing project fields',
    editingProjectTeams: 'Editing project teams',
    creatingNewProfiles: 'Creating new profiles',
    editingProfiles: 'Editing profiles',
    generatingReports: 'Generating reports',
    creatingRequirements: 'Creating requirements',
    acceptingAndDeletingRequirements: 'Accepting and deleting requirements',
    receivingNotificationsOfNewComments: 'Receiving notifications of new comments',
    viewingUnverifiedRequirements: 'Viewing unverified requirements',
    viewingRequirementsOfExternalRegulatoryAuthorities: 'Viewing requirements of external regulatory authorities',
    integratingASWithJira: 'Integrating AS with Jira',
    managingRequirementsVerification: 'Managing requirements verification',
    terminatingAcceptanceTests: 'Terminating acceptance tests',
    rolesUpdatedTitle: 'Roles have been updated',
    rolesUpdatedMessage: 'The roles have been updated. Units will appear later.',
  },
  createUnit: {
    pageTitle: 'Unit creation',
    stepSettings: 'Settings',
    stepTheory: 'Theory',
    stepTest: 'Quiz',
    stepPractice: 'Practice',
    stepResult: 'Results',
    newUnit: 'New unit',
    taskOne: 'Task 1',
    task: 'Task',
    codeReview: 'Code review',
    chapterNumber: 'Chapter {{number}}',
    clearChapter: 'Clear chapter',
    addingInfo: 'Double click to add a component',
    chapters: 'Chapters',
    text: 'Text',
    heading1: 'Title 1',
    heading2: 'Title 2',
    heading3: 'Title 3',
    p: 'Paragraph',
    list: 'List',
    listNumeric: 'Numbered',
    listBullet: 'Bulleted',
    table: 'Table',
    columns: 'Columns',
    rows: 'Rows',
    tableTitle: 'Table header',
    cellText: 'Cell text',
    media: 'Media',
    img: 'Image',
    video: 'Video',
    codeExample: 'Code example',
    codeLang: 'Language',
    spacing: 'Spacing',
    spacingSize: 'Spacing {{size}}',
    nesting: 'Nesting',
    hiddenBlock: 'Hidden block',
    tabs: 'Tabs',
    tabCount: 'Tab {{count}}',
    tags: 'Tags',
    tagCount: 'Tag {{count}}',
    specialBlock: 'Special block',
    chapterName: 'Name of chapter',
    title: 'Title',
    link: 'Link',
    linkHelper: 'Specify the full path to the file with the extension. Example: http://domain/video.mp4',
    linkExtsError: 'The link must contain the full path to the file, including the extensions .mp4/webm/ogg',
    file: 'File',
    color: 'Color',
    colorNameWarning: 'Warning',
    colorNameSecondary: 'Definition',
    colorNameMain: 'Table',
    colorNameSuccess: 'Encouragement',
    colorNameError: 'Error',
    colorNameInfo: 'Information',
    quantity: 'Number',
  },
  fileUpload: {
    drag: 'Drag or ',
    chooseFile: 'choose file',
    uploadRules: '{{list}}, no more than {{maxSize}} MB',
    limitError: 'The file exceeds the allowed limit',
    extensionError: 'Invalid file format',
    fileInfo: '{{ext}}, size {{size}} MB',
  },
  ad: {
    table: {
      active: 'Active',
    },
    deleteRowModal: {
      title: 'Delete the profile',
      message: 'There are no users participating in projects in this LDAP profile.',
      confirmationMessage: 'Are you sure you want to delete {{name}} profile?',
    },
    unableToDeleteRowModal: {
      title: 'Unable to delete the profile',
      message1:
        'This LDAP profile contains users participating in projects. To delete the {{name}} profile, you must unlink users from projects.',
      message2: 'Users participating in projects in a given LDAP profile:',
      inProjects: 'in projects',
    },
    addModal: {
      title: 'Add Active Directory',
      useAuthenticationWithThisASByDefault: 'Use authentication with this AS by default:',
      activateThisConnection: 'Activate this connection:',
      addLink: 'Add link',
      fillBothFields: 'Please fill both fields',
    },
  },
  createProject: {
    nameTitle: 'Product/project name',
    nameSubtitle: 'This field should contain product/project name.',
    idTitle: 'Product/project ID',
    idSubtitle:
      'This field can be populated with a product or project ID. If the product or project does not have an ID, leave the field blank.',
    jiraLinkTitle: 'Link to the project in Jira',
    jiraLinkSubtitle: 'Copy and paste the project link into Jira.',
    summaryTitle: 'Summary',
    summarySubtitle: 'Any additional project info.',
  },
  systemSpecificationsPage: {
    name: 'Name',
    "description": 'Description',
    ordering: "Ordering",
    "active": "Active",
    "notActive": "Not Active",
    showIfHaveValues: 'Show if there are values',
    showIfHaveNotValues: 'Show if there are no values',
    status: "Status",
    category: 'Category',
    categoryName: "Category - {{name}}",
    categoryUntitled: 'Untitled',
    categoryEntity:  "Category entity {{name}}"
  },
  managerFilter: {
    searching: 'Searching...',
    noResults: 'No Results',
    manager: 'Manager IS',
    summarySubtitle: 'Any additional project info.',
  },
  uploadImagePreview: {
    imageIsTooBig: 'The image is too large. Please upload an image no larger than 5 MB.',
    incorrectImageFormat: 'Incorrect file format. Valid formats: PNG, JPG, JPEG.',
    dragAndDropMessage_1: 'drag and drop or',
    dragAndDropMessage_2: 'select a file',
    aspectRatio: 'aspect ratio 4 x 3',
    minSize: 'minimum size 600 x 500 px',
    acceptableFormats: 'PNG, JPG, JPEG, no more than 5 MB',
    backgroundRequirements: 'transparent or monochrome background',
  },
  passingResults: {
    completionTimeAndReward: 'Completion time and reward',
    completionTime: 'Completion time',
    completionTimeDescription:
      'Indicate the approximate amount of time students will spend on the test to balance the training process.',
    rewardDescription:
      'The total number of points per unit is 100. The student can earn 25 points for each correct answer. The final score depends on the number of questions the student answered correctly.',
  },
  codeEditor: {
    turnIntoQuestion: 'Turn into question',
    removeQuestion: 'Remove question',
    rename: "Rename",
  },
  finalResults: {
    successTitle: 'Success (from 70 to 100%)',
    successSubtitle: 'All answers are correct',
    neutralTitle: 'Fine (from 50 to 69%)',
    neutralSubtitle: 'More than 50% of answers are correct, but there is at least one mistake',
    failedTitle: 'Fail (from 0 to 49%)',
    failedSubtitle: 'More than 50% of answers are incorrect',
  },
  buttonsText: {
    newCategory: 'New category',
    newValue: 'New value',
    addButton: 'ADD',
    saveButton: 'SAVE',
  },
};

export default translations;